html {
  background-color: #e64262;
}
body {
  margin: 0;
  padding: 0;

  /* Gradient color */
  background: #e0eafc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    #ffffff,
    #f7f7f7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(#ffffff, #f7f7f7);
  font-family: "Proxima Nova", Georgia, sans-serif;
}

.headers {
  float: left;
  text-align: left;
}

.logo {
  max-width: 240px;
  margin-top: 28px;
  float: left;
}

/* Subscribe buttons */

.subscribe-buttons {
  padding-top: 60px;
  padding-bottom: 20px;
}

.button {
  border-radius: 38px;
  padding-top: 9px;
  padding-bottom: 11px;
  padding-left: 20px;
  padding-right: 25px;
  font-size: 17px;
  text-decoration: none;
  -webkit-text-decoration: none;
  box-shadow: 0 1px 2px 0px rgba(31, 45, 61, 0.15);
  color: white;
  margin: 10px;
}

.button:hover {
  transform: scale(1.02);
  box-shadow: 1px 1px 16px rgba(31, 45, 61, 0.18);
  cursor: pointer;
}

.button,
.button:hover,
.button:active,
.button:visited,
.button:focus {
  text-decoration: none;
  color: white;
}

.slack-button {
  float: right;
  margin-top: 45px;
}
/* Mobile view */

@media screen and (max-width: 700px) {
  .mobile-hidden {
    display: none;
  }
  .subscribe-buttons {
    padding-bottom: 30px;
  }
  .slack-button {
    margin-top: 25px;
  }
}

@media screen and (min-width: 700px) {
  .breaking-div {
    display: none;
  }
}

/* button styles */
.newsletter {
  background: #b3b3b3;
}

.requestevent {
  background: #f1d155;
}

.submitevent {
  /* border: 5px solid #18bfff; */
}
.submiteventsubmit {
  background: #18bfff;
  border-radius: 38px;
  padding-top: 8px;
  padding-bottom: 9px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: -95px;
  display: inline-block;
  width: 110px;
  color: white;
  font-size: 17px;
  cursor: pointer;
  position: relative;
}

textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

.loader {
  border: 2px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 2px solid #18bfff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-bottom: -3px;
}

input {
  border: 0;
  background: inherit;
}
.messenger {
  background: -moz-linear-gradient(
    45deg,
    rgba(33, 150, 243, 1) 0%,
    rgba(33, 150, 243, 1) 77%,
    rgba(0, 188, 212, 1) 100%
  ); /* ff3.6+ */
  background: -webkit-gradient(
    linear,
    left bottom,
    right top,
    color-stop(0%, rgba(33, 150, 243, 1)),
    color-stop(77%, rgba(33, 150, 243, 1)),
    color-stop(100%, rgba(0, 188, 212, 1))
  ); /* safari4+,chrome */
  background: -webkit-linear-gradient(
    45deg,
    rgba(33, 150, 243, 1) 0%,
    rgba(33, 150, 243, 1) 77%,
    rgba(0, 188, 212, 1) 100%
  ); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(
    45deg,
    rgba(33, 150, 243, 1) 0%,
    rgba(33, 150, 243, 1) 77%,
    rgba(0, 188, 212, 1) 100%
  ); /* opera 11.10+ */
  background: -ms-linear-gradient(
    45deg,
    rgba(33, 150, 243, 1) 0%,
    rgba(33, 150, 243, 1) 77%,
    rgba(0, 188, 212, 1) 100%
  ); /* ie10+ */
  background: linear-gradient(
    45deg,
    rgba(33, 150, 243, 1) 0%,
    rgba(33, 150, 243, 1) 77%,
    rgba(0, 188, 212, 1) 100%
  ); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00bcd4', endColorstr='#2196f3',GradientType=1 ); /* ie6-9 */
  background-size: contain;
}
/* Slack button */

#slack_SPAN_1 {
  color: rgb(68, 80, 91);
  text-align: left;
  text-decoration: none solid rgb(68, 80, 91);
  text-rendering: geometricPrecision;
  column-rule-color: rgb(68, 80, 91);
  perspective-origin: 0px 0px;
  transform-origin: 0px 0px;
  caret-color: rgb(68, 80, 91);
  border: 0px none rgb(68, 80, 91);
  font: normal normal 400 normal 16px / normal "HarmoniaSans W01", -apple-system,
    Arial, sans-serif;
  margin: 0px 0px 0px 20px;
  outline: rgb(68, 80, 91) none 0px;
} /*#slack_SPAN_1*/

#slack_SPAN_1:after {
  color: rgb(68, 80, 91);
  text-align: left;
  text-decoration: none solid rgb(68, 80, 91);
  text-rendering: geometricPrecision;
  column-rule-color: rgb(68, 80, 91);
  caret-color: rgb(68, 80, 91);
  border: 0px none rgb(68, 80, 91);
  font: normal normal 400 normal 16px / normal "HarmoniaSans W01", -apple-system,
    Arial, sans-serif;
  outline: rgb(68, 80, 91) none 0px;
} /*#slack_SPAN_1:after*/

#slack_SPAN_1:before {
  color: rgb(68, 80, 91);
  text-align: left;
  text-decoration: none solid rgb(68, 80, 91);
  text-rendering: geometricPrecision;
  column-rule-color: rgb(68, 80, 91);
  caret-color: rgb(68, 80, 91);
  border: 0px none rgb(68, 80, 91);
  font: normal normal 400 normal 16px / normal "HarmoniaSans W01", -apple-system,
    Arial, sans-serif;
  outline: rgb(68, 80, 91) none 0px;
} /*#slack_SPAN_1:before*/

#slack_SPAN_2 {
  color: rgb(68, 80, 91);
  text-align: left;
  text-decoration: none solid rgb(68, 80, 91);
  text-rendering: geometricPrecision;
  column-rule-color: rgb(68, 80, 91);
  perspective-origin: 0px 0px;
  transform-origin: 0px 0px;
  caret-color: rgb(68, 80, 91);
  border: 0px none rgb(68, 80, 91);
  font: normal normal 400 normal 16px / normal "HarmoniaSans W01", -apple-system,
    Arial, sans-serif;
  outline: rgb(68, 80, 91) none 0px;
} /*#slack_SPAN_2*/

#slack_SPAN_2:after {
  color: rgb(68, 80, 91);
  text-align: left;
  text-decoration: none solid rgb(68, 80, 91);
  text-rendering: geometricPrecision;
  column-rule-color: rgb(68, 80, 91);
  caret-color: rgb(68, 80, 91);
  border: 0px none rgb(68, 80, 91);
  font: normal normal 400 normal 16px / normal "HarmoniaSans W01", -apple-system,
    Arial, sans-serif;
  outline: rgb(68, 80, 91) none 0px;
} /*#slack_SPAN_2:after*/

#slack_SPAN_2:before {
  color: rgb(68, 80, 91);
  text-align: left;
  text-decoration: none solid rgb(68, 80, 91);
  text-rendering: geometricPrecision;
  column-rule-color: rgb(68, 80, 91);
  caret-color: rgb(68, 80, 91);
  border: 0px none rgb(68, 80, 91);
  font: normal normal 400 normal 16px / normal "HarmoniaSans W01", -apple-system,
    Arial, sans-serif;
  outline: rgb(68, 80, 91) none 0px;
} /*#slack_SPAN_2:before*/

#slack_A_3 {
  box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px;
  color: rgb(0, 0, 0);
  display: inline-flex;
  height: 18px;
  letter-spacing: 0.5px;
  text-align: left;
  text-decoration: none solid rgb(0, 0, 0);
  text-rendering: geometricPrecision;
  text-transform: uppercase;
  white-space: nowrap;
  width: 87.9219px;
  column-rule-color: rgb(0, 0, 0);
  align-items: center;
  justify-content: center;
  perspective-origin: 64.9531px 24px;
  transform-origin: 64.9531px 24px;
  caret-color: rgb(0, 0, 0);
  background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 8px 8px;
  font: normal normal 700 normal 14px / normal "HarmoniaSans W01", -apple-system,
    Arial, sans-serif;
  outline: rgb(0, 0, 0) none 0px;
  padding: 14px 20px;
  transition: all 0.2s ease-out 0s;
} /*#slack_A_3*/

#slack_A_3:after {
  color: rgb(0, 0, 0);
  letter-spacing: 0.5px;
  text-align: left;
  text-decoration: none solid rgb(0, 0, 0);
  text-rendering: geometricPrecision;
  text-transform: uppercase;
  white-space: nowrap;
  column-rule-color: rgb(0, 0, 0);
  caret-color: rgb(0, 0, 0);
  border: 0px none rgb(0, 0, 0);
  font: normal normal 700 normal 14px / normal "HarmoniaSans W01", -apple-system,
    Arial, sans-serif;
  outline: rgb(0, 0, 0) none 0px;
} /*#slack_A_3:after*/

#slack_A_3:hover {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.25);
}
#slack_A_3:before {
  color: rgb(0, 0, 0);
  letter-spacing: 0.5px;
  text-align: left;
  text-decoration: none solid rgb(0, 0, 0);
  text-rendering: geometricPrecision;
  text-transform: uppercase;
  white-space: nowrap;
  column-rule-color: rgb(0, 0, 0);
  caret-color: rgb(0, 0, 0);
  border: 0px none rgb(0, 0, 0);
  font: normal normal 700 normal 14px / normal "HarmoniaSans W01", -apple-system,
    Arial, sans-serif;
  outline: rgb(0, 0, 0) none 0px;
} /*#slack_A_3:before*/

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.footer-trail {
  color: grey;
}

.footer-trail a {
  color: #333;
}

